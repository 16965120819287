// @see: https://www.gatsbyjs.com/docs/client-only-routes-and-user-authentication/
// @see: https://gatsbyguides.com/tutorial/create-client-only-routes
// @see: https://www.gatsbyjs.org/docs/client-only-routes-and-user-authentication/
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/68
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/93

import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout';

// s4n
// import PrivateRoute from '../components/s4n/AccountManagement/PrivateRoute';
import Checkout from '../components/s4n/Shop/ext/pages/Checkout';
import PaymentConfirmed from '../components/s4n/Shop/ext/pages/PaymentConfirmed';
import Default from './../components/s4n/RouterDefaultRedirectTo';

const CheckoutRoute = () => {
  return (
    <Layout className={`checkout__page`}>
        <Router
          basepath="/:locale/checkout" // If we provide basepath we do need prtovide full path in each route just last part, it will concatenate automatically!!!
        >
  {/* 
          <Route path={`/checkout/:orderId/complete`} component={PaymentConfirmed} />
          <Route path={`/checkout/:orderId`} component={Checkout} />
  */}
          <Checkout path={`/:orderUuid`} />
          <PaymentConfirmed path={`/:orderUuid/complete`} />
          <Default path="/" redirectTo={`/cart`} />
        </Router>
    </Layout>
  )
}

export default CheckoutRoute;